@import './other-sellers';

.#{$pdp-prefix-other-sellers} {
  .ui-pdp-summary-list__item {
    margin-bottom: 6px;
    position: relative;

    &::before {
      content: '';
      height: 3px;
      width: 3px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 8px;
    }

    .ui-pdp-summary-list__text {
      line-height: 14px;
      margin-left: 10px;
    }
  }

  &-item__tag {
    top: 9px;
    right: 14px;
    margin: 1px 0 0;
  }

  &-item__cbt {
    margin-top: 16px;
    margin-bottom: -8px;

    .ui-pdp-media__title {
      margin-bottom: 0;
    }

    .ui-pdp-media__figure {
      margin: 2px -2px 0 0;

      .ui-pdp-icon--cbt-world {
        height: 15px;
        width: 15px;
      }
    }

    .ui-pdp-icon--cbt-summary {
      width: 154px;
      height: 12px;
    }
  }

  .ui-pdp-price__second-line__label {
    font-size: $font-size-14;
    margin-left: 6px;
  }

  .ui-pdp-price__original-value {
    font-size: $font-size-14;
    margin-bottom: 1px;
  }

  .ui-pdp-price__tags {
    margin-top: 12px;

    &__wrapper-tags {
      align-items: baseline;
      margin-bottom: 4px;

      &:last-of-type {
        margin-bottom: 0;

        .ui-pdp-promotions-pill-label--info {
          padding-top: 4px;
        }

        .ui-pdp-promotions-pill-label--tag {
          margin-bottom: 0;
        }
      }
    }

    .ui-pdp-promotions-pill-label {
      text-transform: none;
      margin-bottom: 0;
      margin-right: 5px;
      padding: 3px 5px;

      &--info {
        padding: 5px 0 0;
        line-height: 0.9;
        margin-bottom: 0;
      }
    }
  }

  .ui-pdp-price__subtitles {
    margin-top: 1px;
  }

  &-item__list-top {
    flex-wrap: wrap;
    padding-bottom: 13px;
  }

  .ui-pdp-buybox {
    border: 0;
    padding: 0;
  }

  .ui-pdp-actions__container {
    display: flex;
    align-items: center;
  }
}

// fix buttons size for MLB
[data-country='BR'] .#{$pdp-prefix-other-sellers}-item__actions .ui-pdp-actions__container {
  .andes-button {
    font-size: 12px;
    padding: 0 2px;
    margin-left: 7px;

    &:first-child {
      margin-left: 0;
    }
  }
}
