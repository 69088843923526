@import '../../../../styles/variables';

.#{$pdp-prefix}-cart-actions {
  width: 100%;

  &__card-tooltip {
    margin-top: 26px;

    .#{$pdp-prefix}-card-tooltip__card__content {
      align-items: flex-start;
    }
  }
}
