@import '../../../styles/variables';

.#{$pdp-prefix}-description {
  color: color(color_gray_dark);

  &__content {
    font-weight: $font-weight-regular;
    font-size: $font-size-16;
    word-break: break-word;
  }
}
