$gradings-prefix: #{$pdp-prefix}-gradings;

.#{$gradings-prefix} {
  &-wrapper {
    .andes-button {
      padding: 0;
      margin: $andes-spacing-8 2px 0 $andes-spacing-16;
      font-weight: 400;
    }
  }
}
