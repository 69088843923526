@import '../components/content-trade-in/';

$trade-in-prefix: #{$pdp-prefix}-trade-in;

.#{$trade-in-prefix} {
  position: relative;
  width: 100%;

  &__card {
    margin-top: $andes-spacing-24;

    &--content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: $andes-spacing-12 0 $andes-spacing-16;

      .text {
        width: 100%;
      }

      .text__title {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $andes-gray-100-solid;
        padding: $andes-spacing-12 $andes-spacing-16;

        &--info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        &--primary {
          padding: $andes-spacing-12 $andes-spacing-16;
        }

        .ui-pdp-media__figure {
          height: 18px;
          width: $andes-spacing-16;
          margin-right: 6px;
        }

        .ui-pdp-icon {
          width: 14px;
          height: 15px;
        }

        .text__plan-canje {
          display: inherit;
          align-items: center;
        }

        .ui-pdp-action-modal {
          margin-top: 0;
        }

        .andes-tooltip__trigger {
          position: unset;
        }

        .ui-pdp-action-modal__link {
          display: flex;
          font-size: $font-size-12;
          font-weight: $font-weight-semibold;
        }
      }

      .text__second-line {
        span {
          display: inline;
        }

        .ui-pdp-price__part {
          font-weight: $font-weight-semibold;
        }
      }
    }
  }

  &__spinner-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $andes-white;
    opacity: 0.9;
    z-index: 1;

    &__spinner {
      display: unset;
      left: calc(50% - $andes-spacing-16);
      top: calc(50% - $andes-spacing-16);
    }
  }

  &__skeleton {
    margin-top: $andes-spacing-40;
    margin-bottom: $andes-spacing-16;
  }

  .ui-pdp-media__title {
    font-weight: $font-weight-semibold;
    font-size: $font-size-14;
  }
}

.ui-pdp-remove-trade-in {
  .andes-modal__scroll {
    padding: $andes-spacing-32;

    .andes-modal__header {
      padding: 0;
    }

    .andes-modal__header .andes-modal__title {
      font-size: $font-size-24;
    }

    .andes-modal__content {
      display: flex;
      flex-direction: column;
      font-size: $font-size-16;
      padding-bottom: $andes-spacing-32;
      gap: $andes-spacing-12;
    }

    .remove-trade-in-icon {
      height: $andes-spacing-64;
      width: $andes-spacing-64;
      margin-bottom: 18px;

      .ui-pdp-media__figure svg {
        height: $andes-spacing-64;
        width: $andes-spacing-64;
      }
    }

    .andes-modal__close-button {
      display: none;
    }
  }

  &.andes-modal--full {
    text-align: center;

    .andes-modal__scroll {
      display: flex;
      justify-content: space-between;
    }

    .andes-modal__content {
      flex-grow: 0;
      align-items: center;
      padding: 0;
      gap: $andes-spacing-8;
    }

    .andes-modal__actions {
      padding-bottom: 0;
    }

    .andes-modal__close-button {
      display: unset;
    }
  }
}

.ui-pdp-trade-in-modal {
  .andes-modal__scroll {
    .andes-modal__header {
      display: none;
    }

    .andes-modal__content {
      height: 100%;
      padding: 0;
    }
  }

  &__how-works {
    width: 800px;
  }
}

.ui-pdp-trade-in-modal__bottom-sheet[data-testid='bottom-sheet'] {
  transition-duration: 0.2s !important;

  .andes-bottom-sheet__header {
    display: none;
  }

  .andes-bottom-sheet__content {
    padding: 0;
  }
}
