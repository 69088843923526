// sass-lint:disable-all
@import './generic-summary';

.#{$pdp-prefix}-generic-summary {
  position: relative;
  width: 100%;

  &__map-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 3rem;
    font-weight: 400;
    min-width: 400px;
  }

  &__message {
    margin: 20px 0 20px 30px;
    margin-bottom: 0;
  }

  &__spinner {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: -16px;
  }
}

@media (width >= 768px) {
  .#{$pdp-prefix}-generic-summary-modal.andes-modal {
    /* stylelint-disable-next-line selector-class-pattern */
    &.meliplus_summary__modal {
      background-color: $andes-gray-040-solid;
    }

    // using component id to add class selector
    &.shipping_summary__modal, // sass-lint:disable-line class-name-format
    &.second_shipping_summary__modal, // sass-lint:disable-line class-name-format
    &.#{$pdp-prefix}-shipping-modal {
      .andes-modal__header,
      .andes-modal__header--sticky {
        padding: $andes-spacing-48 $andes-spacing-48 0;
        position: relative;
        background: none;
        z-index: 1;
      }

      .andes-modal__content {
        margin-top: (-$andes-spacing-28);
        position: relative;
        z-index: 0;
      }

      .andes-modal__header--sticky {
        box-shadow: none;
      }
    }

    &.#{$pdp-prefix}-action-modal__modal--no-title {
      .andes-modal__content {
        margin: 0;
      }
    }
  }
}
