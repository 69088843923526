@import './enhanced-descriptions';

.pdp-enhanced-descriptions {
  &__title {
    font-size: $font-size-24;
    padding: 0;
  }

  &__image {
    padding: 0;
  }
}
