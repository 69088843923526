@import '../../styles/mixins';

.#{$pdp-prefix}-products {
  margin-top: $andes-spacing-32;

  &__label {
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    line-height: $line-height-s;
  }

  &__list {
    margin-top: $andes-spacing-8;
  }

  &__link {
    @include money-amount-spacing;

    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    display: inline-flex;
    line-height: $line-height-s;
    margin-top: $andes-spacing-4;

    .ui-pdp-price__part {
      line-height: $line-height-s;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .price-tag {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }
}
