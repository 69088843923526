@import '../../styles/mixins';
@import '../../styles/variables';
@import './other-sellers-item';

$pdp-prefix-other-sellers: 'ui-pdp-other-sellers';

.#{$pdp-prefix-other-sellers} {
  $block: &;

  width: 100%;
  border-radius: 8px;
  border: 1px solid $andes-gray-100;

  .ui-pdp-price__original-value {
    margin-left: 1px;
    font-size: $font-size-12;
  }

  .ui-pdp-price__second-line {
    &__label {
      font-size: $font-size-12;
    }
  }

  &__title {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    line-height: 16px;
    color: color(color_gray_dark);
    padding: $andes-spacing-16;
    border-bottom: 1px solid $andes-gray-100;
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    line-height: 18px;
    padding: $andes-spacing-16;
    justify-content: left;

    .ui-pdp-price__part {
      color: inherit;
      font-weight: inherit;
      margin-left: $andes-spacing-4;
      line-height: 18px;
    }
  }
}
