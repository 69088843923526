@import '~@andes/common/lib/styles/visually-hidden';
@import './variables';
@import './functions';
@import './mixins';

main {
  // sass-lint:disable no-vendor-prefixes //
  -webkit-font-smoothing: antialiased;
}

// Colors

.#{$pdp-prefix}-color--GREEN {
  color: color(color_green);
}

.#{$pdp-prefix}-color--GREEN_60 {
  color: color(color_green_60);
}

.#{$pdp-prefix}-color--GRAY {
  color: color(color_gray_middle);
}

.#{$pdp-prefix}-color--GRAY_40 {
  color: color(color_gray_lighter);
}

.#{$pdp-prefix}-color--LIGHT_GRAY {
  color: color(color_gray_light);
}

.#{$pdp-prefix}-color--BLACK {
  color: color(color_gray_dark);
}

.#{$pdp-prefix}-color--BLACK_100 {
  color: color(color_black_100);
}

.#{$pdp-prefix}-color--RED {
  color: color(color_red);
}

.#{$pdp-prefix}-color--RED_600 {
  color: color(color_red_600);
}

.#{$pdp-prefix}-color--ORANGE {
  color: color(color_orange);
}

.#{$pdp-prefix}-color--ORANGE_200 {
  color: color(color_orange_200);
}

.#{$pdp-prefix}-color--ORANGE_600 {
  color: color(color_orange_600);
}

.#{$pdp-prefix}-color--ORANGE_MEDIUM {
  color: color(color_orange_medium);
}

.#{$pdp-prefix}-color--BLUE {
  color: color(color_blue);
}

.#{$pdp-prefix}-color--BLUE_600 {
  color: color(color_blue_600);
}

.#{$pdp-prefix}-color--BLUE_700 {
  color: color(color_blue_700);
}

.#{$pdp-prefix}-color--WHITE {
  color: color(color_white);
}

.#{$pdp-prefix}-color--LIGHT_BROWN {
  color: color(color_light_brown);
}

// Background Colors

.#{$pdp-prefix}-background-color--GREEN {
  background-color: color(color_green);
}

.#{$pdp-prefix}-background-color--GREEN_60 {
  background-color: color(color_green_60);
}

.#{$pdp-prefix}-background-color--GRAY {
  background-color: color(color_gray_middle);
}

.#{$pdp-prefix}-background-color--GRAY_40 {
  background-color: color(color_gray_lighter);
}

.#{$pdp-prefix}-background-color--LIGHT_GRAY {
  background-color: color(color_gray_light);
}

.#{$pdp-prefix}-background-color--MEDIUM_GRAY {
  background-color: color(color_medium_gray);
}

/* stylelint-disable-next-line no-duplicate-selectors */
.#{$pdp-prefix}-background-color--LIGHT_GRAY {
  background-color: color(color_black_10);
}

.#{$pdp-prefix}-background-color--BLACK {
  background-color: color(color_gray_dark);
}

.#{$pdp-prefix}-background-color--BLACK_10 {
  background-color: color(color_black_10);
}

.#{$pdp-prefix}-background-color--RED {
  background-color: color(color_red);
}

.#{$pdp-prefix}-background-color--RED_600 {
  background-color: color(color_red_600);
}

.#{$pdp-prefix}-background-color--ORANGE {
  background-color: color(color_orange);
}

.#{$pdp-prefix}-background-color--ORANGE_200 {
  background-color: color(color_orange_200);
}

.#{$pdp-prefix}-background-color--ORANGE_600 {
  background-color: color(color_orange_600);
}

.#{$pdp-prefix}-background-color--BLUE {
  background-color: color(color_blue);
}

.#{$pdp-prefix}-background-color--BLUE_700 {
  background-color: color(color_blue_700);
}

.#{$pdp-prefix}-background-color--LIGHT_BLUE {
  background-color: color(color_light_blue);
}

.#{$pdp-prefix}-background-color--BLUE_10 {
  background-color: color(color_blue_10);
}

.#{$pdp-prefix}-background-color--LIGHT_YELLOW {
  background-color: color(color_light_yellow);
}

.#{$pdp-prefix}-background-color--VIOLET_600 {
  background-color: color(color_violet_600);
}

.#{$pdp-prefix}-background-color--LIGHT_GREEN {
  background-color: color(color_light_green);
}

// Font sizes

.#{$pdp-prefix}-size--XXXSMALL {
  font-size: size(size_xxxsmall);
}

.#{$pdp-prefix}-size--XXSMALL {
  font-size: size(size_xxsmall);
}

.#{$pdp-prefix}-size--XSMALL {
  font-size: size(size_xsmall);
}

.#{$pdp-prefix}-size--SMALL {
  font-size: size(size_small);
}

.#{$pdp-prefix}-size--MEDIUM {
  font-size: size(size_medium);
}

.#{$pdp-prefix}-size--LARGE {
  font-size: size(size_large);
}

.#{$pdp-prefix}-size--XLARGE {
  font-size: size(size_xlarge);
}

.#{$pdp-prefix}-size--XXLARGE {
  font-size: size(size_xxlarge);
}

// Font weights

.#{$pdp-prefix}-family--LIGHT {
  font-weight: weight(weight_light);
}

.#{$pdp-prefix}-family--REGULAR {
  font-weight: weight(weight_regular);
}

.#{$pdp-prefix}-family--SEMIBOLD {
  font-weight: weight(weight_semibold);
}

.#{$pdp-prefix}-family--BOLD {
  font-weight: weight(weight_bold);
}

.#{$pdp-prefix}-background-color--LIGHT_ORANGE {
  /* stylelint-disable-next-line color-function-notation */
  background-color: rgba(color(color_orange), 0.1);
}

/* stylelint-disable-next-line no-duplicate-selectors */
.#{$pdp-prefix}-background-color--LIGHT_GREEN {
  background-color: $pdp-green-100;
}

.#{$vpp-prefix}-text-alignment--center {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

// https://gist.github.com/jacurtis/30da4bf9a6c9b9b5cc0aebac512ca7c9#gistcomment-2389966
// @TODO find a better place for this
$spaceamounts: (0, 2, 4, 5, 8, 10, 12, 14, 15, 16, 20, 24, 25, 30, 32, 35, 40, 45, 48, 64);
$sides: (
  '': 'all',
  't': 'top',
  'b': 'bottom',
  'l': 'left',
  'r': 'right',
);

@each $space in $spaceamounts {
  @each $prefix, $value in $sides {
    $property: if($prefix == '', '', -#{$value});

    .m#{$prefix}-#{$space} {
      margin#{$property}: #{$space}px;
    }

    .p#{$prefix}-#{$space} {
      padding#{$property}: #{$space}px;
    }
  }
}

// z-index
.andes-tooltip {
  z-index: $z-index-tooltip;
}

.ui-pdp-gallery__column {
  z-index: 0;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.non-selectable {
  user-select: none;
}

// BLOCK Meliuz extension
/* stylelint-disable-next-line selector-max-id */
#comparator {
  display: none !important;
}

.#{$pdp-prefix}--hide {
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: 0 -1px -1px 0;
  overflow: hidden;
  padding: 0;
}

// @TODO remove when andes fix this issue because it's overlaping with tooltip
.andes-modal__overlay {
  z-index: 99999;
}

// Styles for loader
.#{$pdp-prefix} {
  $fetcher-loader-color: color(color_gray_05);

  &-fetching-component + ul,
  &-fetching-component + span,
  &-fetching-component + div,
  &-fetching-component + section,
  &-fetching-component + form,
  &-fetching-component + a {
    position: relative;
  }

  &-fetching-component + ul::after,
  &-fetching-component + span::after,
  &-fetching-component + div::after,
  &-fetching-component + section::after,
  &-fetching-component + form::after,
  &-fetching-component + a::after {
    background-color: $fetcher-loader-color;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
  }
}
