@import './description';

.#{$pdp-prefix}-description {
  &__title {
    font-size: $font-size-24;
    font-weight: $font-weight-regular;
    padding-bottom: $andes-spacing-24;
  }

  &__content {
    color: $color;
    font-size: $font-size-20;
  }

  &__message {
    margin-bottom: $andes-spacing-24;
    z-index: $z-index-tooltip;

    .andes-message__content {
      width: 90%;
    }
  }
}

.ui-pdp-description-collapse {
  /* stylelint-disable-next-line selector-no-qualifying-type */
  a.ui-pdp-collapsable__action {
    padding-left: $andes-spacing-24;
  }
}

.#{$pdp-prefix}-description-collapse-rex {
  padding-bottom: $andes-spacing-40;
  border-top: 1px solid rgb(0 0 0 / 10%);
  padding-top: $andes-spacing-40;

  p {
    font-size: $font-size-16;
  }
}
