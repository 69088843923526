@import '../../../styles/variables';

.pdp-enhanced-descriptions {
  &__content {
    display: block;
  }

  &__title {
    padding: 0 $andes-spacing-20;
    font-size: $font-size-20;
    font-weight: $font-weight-regular;
    margin-bottom: $andes-spacing-32;
  }

  &__image-wrapper {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    padding: 0 $andes-spacing-20;
    vertical-align: top;
  }

  &__pictures {
    display: contents;
  }
}
