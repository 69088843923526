@import './action-collapsable';

.#{$pdp-prefix}-collapsable {
  $self: &;

  &#{$self}--is-collapsed {
    #{$self}__container {
      &::after {
        background-image: linear-gradient(
          to bottom,
          color(color_black_1000),
          color(color_gray_500) 85%,
          $andes-white 100%
        );
        left: 0;
      }

      // TODO: AB para PDP cuando no viene descripción. Borrarlo cuando no se este usando mas.
      &-items-hidden {
        .#{$pdp-prefix}-list__wrapper:nth-child(n + 12) {
          display: none;
        }

        .#{$vip-core-prefix} & {
          .#{$pdp-prefix}-list__wrapper:nth-child(n + 7) {
            display: none;
          }
        }
      }
    }
  }

  &__action {
    padding-left: 0;
    cursor: pointer;
    margin-top: $andes-spacing-8;
  }
}
