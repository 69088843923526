// @TODO Este fix metido hace tiempo por centrado vertical de andes/button continua
// vigente, ya que en andes 4.3 sigue el problema, levantar un issue en Andes/button
.andes-button__content {
  position: relative;
  top: -1px;
}

// =========================================================================================
// Fixes  Snackbar

// Fix se sobreescribe las clases que agrega dinamicametente el siguiente componente
// y que pisa los valores correctos de Ades 4.3.0
// https://http2.mlstatic.com/resources/frontend/statics/ml-widgets/snackbar/v1.0.0/snackbar.js

.ui-snackbar {
  z-index: 100000;
}

.andes-snackbar {
  // Esto esta en la demo de andes, pero por alguna razon no viene el css de la 4.3.0
  left: 0;
  right: 0;
  z-index: 100000;
  bottom: 2em;

  &--animate-show {
    animation-name: snackbar-show-fix;
  }

  &--animate-hide {
    animation-name: snackbar-hide-fix;
    animation-fill-mode: forwards;
  }
}

// Se sobreescriben estas clase hasta que se solucione el issue de especificidad de Andes
.andes-snackbar.andes-snackbar--red {
  background-color: $andes-error-red;
}

.andes-snackbar.andes-snackbar--green {
  background-color: $andes-success-green;
}

.andes-snackbar__message {
  font-weight: 400;
}

@keyframes snackbar-show-fix {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
  }
}

@keyframes snackbar-hide-fix {
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

// =========================================================================================

// Dropdown
.andes-dropdown__arrow {
  &::after {
    border-color: $andes-blue-500;
  }
}

.andes-dropdown__trigger:hover {
  .andes-dropdown__arrow {
    &::after {
      border-color: $andes-blue-500;
    }
  }
}

// =========================================================================================

// Modal
.andes-modal--full .andes-modal__header {
  &.andes-modal__header--sticky .andes-modal-title {
    text-align: left;
    padding-left: 45px;
  }
}

.andes-modal__close-button path {
  stroke: $andes-blue-500;
}

.andes-modal__overlay--card {
  .andes-modal__close-button path {
    stroke: none;
  }
}

.andes-snackbar__action {
  padding-right: 1.5em;
}

// =========================================================================================
// Fixes Table
// Fix se sobreescribe las columnas de las tablas que agrega dinamicametente el siguiente componente andes-table__column--value
// y que pisa los valores correctos de Ades 8.0.0
// https://github.com/melisource/fury_frontend-andes-ui/blob/master/packages/table/stories/table.story.js

.andes-table {
  border-collapse: collapse;
  border: 1px solid $andes-gray-040-solid;
}

.andes-table__header {
  box-shadow: none;
  background-color: $andes-gray-040-solid;
}

.andes-table__column,
.andes-table__header {
  display: table-cell;
  box-shadow: none;
  padding: 15px 16px;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
}

.andes-table__column:last-of-type,
.andes-table__header:last-of-type {
  padding: 15px 24px 15px 16px;
}

.andes-table__row {
  box-shadow: none;
}

.andes-table__column--value {
  margin: 0;
}

.andes-table__header__container {
  background-color: transparent;
  border: none;
  display: block;
  font: inherit;
  padding: 0;
}

// FIXME: This is a temporary fix for the table component
.ui-recommendations-comparator__table tbody tr:nth-child(odd),
.ui-recommendations-comparator__table tbody tr:nth-child(odd):hover {
  background: transparent;
}

.ui-recommendations-comparator__table tbody tr:nth-child(odd) .andes-table__header:first-child {
  border-bottom: 1px solid #eee;
  background: $andes-gray-070-solid;
}

.ui-recommendations-comparator__table__header {
  background-color: transparent;
}

.ui-recommendations-comparator__table .andes-table__body .andes-table__row .andes-table__column {
  border-bottom: 1px solid #eee;
  border-right: none;
  border-left: none;
}

.ui-recommendations-comparator__table .andes-table__body .andes-table__row .andes-table__column:last-child {
  border-right: 1px solid #eee;
}

// FIXME: Este Fix es temporal una vez que se actualice andes a una version superior a la 8.29.0
.andes-button--transparent {
  // stylelint-disable selector-no-qualifying-type
  path[fill]:not([fill='none'], [fill='#3483FA']) {
    fill: $andes-gray-550 !important;
  }

  :hover path[fill],
  :active path[fill],
  :focus path[fill],
  path[fill='none'] {
    fill: none !important;
  }

  &.active path[fill]:not([fill='none']) {
    fill: $andes-white !important;
  }

  &:not(.active):hover path[fill]:not([fill='none']) {
    fill: $andes-blue-500 !important;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover path[fill]:not([fill='none']) {
      fill: $andes-blue-500 !important;
    }
  }
}

.andes-button--loud path[fill],
.andes-button--loud:hover path[fill],
.andes-button--loud:focus path[fill],
.andes-button--loud:active path[fill] {
  fill: none !important;
}
