@import '../../../styles/variables';

.#{$pdp-prefix}-collapsable {
  $self: &;

  width: 100%;

  &#{$self}--is-collapsed {
    #{$self}__container {
      position: relative;
      overflow: hidden;
      margin-top: -1px;
      padding-top: 1px;

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        background-image: linear-gradient(
          to bottom,
          color(color_black_1000),
          color(color_gray_500) 85%,
          $andes-white 100%
        );
      }
    }
  }

  &#{$self}--is-collapsed#{$self}--is-gradient-small {
    #{$self}__container {
      &::after {
        background-image: linear-gradient(to top, $andes-white, transparent 10%);
      }
    }
  }

  &__action {
    align-items: center;
    color: $andes-blue-500;
    display: inline-flex;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    padding: $andes-spacing-8 $andes-spacing-16;
    background-color: transparent;
    border: none;

    &:focus {
      outline: 0;
    }
  }

  &__chevron {
    margin-left: 6px;
    margin-top: $andes-spacing-4;
  }
}

.#{$pdp-prefix}-collapsable-mobile {
  $self: &;

  width: 100%;

  &#{$self}--is-collapsed {
    #{$self}__container {
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        width: 100%;
        height: $andes-spacing-48;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        background-image: linear-gradient(to bottom, color(color_white_01), color(color_white));
      }
    }
  }

  &__action {
    align-items: center;
    color: $andes-blue-500;
    display: inline-flex;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    padding: $andes-spacing-8 $andes-spacing-16;

    &:focus {
      outline: 0;
    }
  }

  &__chevron {
    margin-left: 6px;
    margin-top: $andes-spacing-4;
  }
}
